import client from './PQClient';
import './App.css';
import Plotly from 'plotly.js-dist';
import React, { useState, useRef, useEffect } from 'react';
import logo from './images/logo512.png';
import audibleImage from './images/EnjoyingAudiobook.jpg';
import disagreeImage from './images/disagree.svg';
import agreeImage from './images/agree.svg';


const econGradeDefinitions = new Map([
  [-1, "No answer"],
  [0, "No answer"],
  [1, "Socalist"],
  [2, "Leans socialist"],
  [3, "Center"],
  [4, "Leans capitalist"],
  [5, "Capitalist"],
]);

const libertyGradeDefinitions = new Map([
  [-1, "No answer"],
  [0, "No answer"],
  [1, "Libertarian"],
  [2, "Leans libertarian"],
  [3, "Center"],
  [4, "Leans authoritarian"],
  [5, "Authoritarian"],
]);

function placeLabel(econ, liberty){
  if(econ < 3 && liberty < 3){
    return 'libertarian socialist';
  }
  else if(econ < 3 && liberty > 3){
    return 'authoritarian socialist';
  }
  else if(econ > 3 && liberty < 3){
    return 'libertarian capitalist';
  }
  else if(econ > 3 && liberty > 3){
    return 'authoritarian capitalist';
  }
  return 'center';
}

const ads = new Map([
  [ "libertarian socialist", [
    { image: require("./images/ConquestOfBread.jpg"), link: "https://amzn.to/3sEo02U", topText: "The Conquest of Bread", bottomText: "Justice & Freedom without rulers" },
    { image: require("./images/HomageToCatalonia.jpg"), link: "https://amzn.to/45TCcTK", topText: "Homage to Catalonia", bottomText: "George Orwell's account of the Spanish Civil War" },
    { image: require("./images/DemandingTheImpossible.jpg"), link: "https://amzn.to/3Er32qx", topText: "Demanding the Impossible", bottomText: "A History of Anarchism" },
  ]],
  [ "libertarian capitalist", [
    { image: require("./images/AtlasShrugged.jpg"), link: "https://amzn.to/3Z0nM22", topText: "Atlas Shrugged", bottomText: "Freedom. Reason. Capitalism. Triumph." },
    { image: require("./images/RoadToSerfdom.jpg"), link: "https://amzn.to/3L3wAOO", topText: "Road To Serfdom", bottomText: "Hayek explains the dangers of socialism." },
    { image: require("./images/AnarchyStateUtopia.jpg"), link: "https://amzn.to/3qR9zYH", topText: "Anarchy, State, and Utopia", bottomText: "A minimal state." },
  ]],
  [ "authoritarian socialist", [
    { image: require("./images/StateAndRevolution.jpg"), link: "https://amzn.to/3EkhfFR", topText: "State and Revolution", bottomText: "Lenin's lightning bolt of Marxism." },
    { image: require("./images/LeninReloaded.jpg"), link: "https://amzn.to/45oBEpb", topText: "Lenin Reloaded", bottomText: "World's leading Marxist intellectuals explain Lenin" },
    { image: require("./images/GovernanceOfChina.jpg"), link: "https://amzn.to/45TwJwa", topText: "Governance of China", bottomText: "by Xi Jinping" },
  ]],
  [ "authoritarian capitalist", [
    { image: require("./images/EndOfHistory.jpg"), link: "https://amzn.to/3EmGHdK", topText: "The End of History and the Last Man", bottomText: "Western Capitalism: Humanity's Pinnacle?" },
    { image: require("./images/WhyNationsFail.jpg"), link: "https://amzn.to/3qZk7os", topText: "Why Nations Fail?", bottomText: "Origins of power, prosperity, and poverty" },
    { image: require("./images/GreatTransformation.jpg"), link: "https://amzn.to/3QYMv4V", topText: "The Great Transformation", bottomText: "Political and economic origins of our time." },
  ]],
]);

function WordCounter(props) {

  return (
    <div className="WordCounter">
      <div className="WordCount">{props.wordCount} / {props.MAX_WORDS} words</div>
    </div>
  );
}

function Modal({ cancelModal, submitModal, questionId, gradeRequestId }) {
  const [ inputText, setInputText ] = useState('');
  return (
    <>
    <div className="overlay" onClick={cancelModal} />
    <div className='modal'>
      <div className="modalTitle">Tell us what you don't like and why</div>
      <textarea className="modalInput" placeholder='provide your answer here' onChange={(e) => setInputText(e.target.value)} value={inputText} />
      <div className="modalButtons">
        <button className="modalButton" onClick={cancelModal}>Cancel</button>
        <button className="modalButton" onClick={
          () => {
            submitModal();
            client.sendFeedback(gradeRequestId, questionId, -1, inputText, () => {
              console.log("Feedback sent");
            }, (error) => {
              console.log(error);
            });
          }
        }>Submit</button>
      </div>
    </div>
    </>
  );
}

function ExplainerModal({ title, explainText, dismissModal }) {
  return (
    <>
    <div className="overlay" onClick={dismissModal} />
    <div className='modal'>
      <div className="modalTitle">{title}</div>
      <div className="modalInput">{explainText}</div>
      <div className="modalButtons">
        <button className="modalButton" onClick={dismissModal}>Dismiss</button>
      </div>
    </div>
    </>
  );
}

function ExplainableText({ text, explanation }) {
  const [ showModal, setShowModal ] = useState(false);

  return (
    <>
      <span className="ExplainableText" onClick={() => setShowModal(true)}>{text}</span>
      { showModal && (
        <ExplainerModal
          title={text}
          explainText={explanation}
          dismissModal={() => setShowModal(false)}
        />
      )}
    </>
  );
}

function ClickToReveal({ concealingText, revealedText }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="ClickToReveal">
      <div className={(!isExpanded ? "ClickToRevealText" : "ClickToConcealText")} onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? revealedText : concealingText}
      </div>
    </div>
  );
}

function GradedAnswer(item) {

  const { prompt, answer, econGrade, econGradeText, libertyGrade, libertyGradeText, questionId, gradeRequestId } = item;
  const [ agree, setAgree ] = useState(false);
  const [ disagree, setDisagree ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);

  const clickedAgree = () => {
    if(!agree){
      client.sendFeedback(
        gradeRequestId,
        questionId,
        1,
        "User liked this feedback.",
        () => {
          console.log("Feedback sent");
        },
        (error) => {
          console.log("Error sending feedback on agree", error);
        }

      )
    }
    setAgree(!agree);
    setDisagree(false);


  }

  const clickedDisagree = () => {
    setAgree(false);

    if(!disagree){
      setShowModal(true);
    }

    setDisagree(!disagree);


  }

  return (
    <div className="GradedAnswer">
      <div className="GradedAnswerColumn">
        <div className="GradedAnswerPrompt">{prompt}</div>
        
        <ClickToReveal concealingText={"Click to see answer."} revealedText={answer} />
        <div className="miniSpacer" />

        <div className="GradedAnswerGrades">
          <div className="EconGradeColumn">
            <div className="GradedAnswerGrade EconGrade"><b>Economics</b>: {econGrade}</div>
            <div className="GradedAnswerGrade EconGrade">{econGradeDefinitions.get(econGrade)}</div>
            <ClickToReveal concealingText={"Click to explain."} revealedText={econGradeText} />
          </div>
          <div className="SocialsGradeColumn">
            <div className="GradedAnswerGrade SocialsGrade"><b>Civics</b>: {libertyGrade}</div>
            <div className="GradedAnswerGrade SocialsGrade">{libertyGradeDefinitions.get(libertyGrade)}</div>
            <ClickToReveal concealingText={"Click to explain."} revealedText={libertyGradeText} />
          </div>
        </div>
      </div>
      {econGrade > 0 && libertyGrade > 0 && <Graph coords={ [econGrade, libertyGrade] } />}
      <div className="miniSpacer" />
      <div className="sentimentCollector">
        <img src={disagreeImage} className={(disagree ? "active " : "") + "sentimentButton" } alt="disagree" onClick={clickedDisagree}/>
        <img src={agreeImage} className={(agree ? "active " : "") + "sentimentButton" } alt="agree" onClick={clickedAgree} />
      </div>
      { showModal && (
        <Modal 
          cancelModal={() => {
            setShowModal(false);
            setDisagree(false);
          }}
          submitModal={() => {
            setShowModal(false);
          }}
          questionId={questionId}
          gradeRequestId={gradeRequestId}
        />
      )}
    </div>
  );
}

function Ad({ image, topText, bottomText, link }) {
  const goToWebsite = () => {
    // open a new tab
    window.open(link, '_blank');
  }

  return (
    <div className="VitalContainer">
      <div className="miniSpacer" />
      <div className="Vital">
        <div className="VitalText">{topText}</div>
        <img src={image} className="VitalImage" alt="ad" onClick={goToWebsite}  />
        <div className="VitalText">{bottomText}</div>
      </div>
      <div className="miniSpacer" />
    </div>
    
  );
}



function QuestionCardFooter({ number, total }) {
  return (
    <div className="QuestionCardFooter">
      <div className="QuestionNumber">Prompt {number} / {total}</div>
    </div>
  );
}

function LoadingIndicator() {
  return (
    <div className="LoadingIndicator">
      <div className="LoadingBarContainer">
        <div className="LoadingBar"></div>
      </div>
      <div className="LoadingIndicatorText">Loading...</div>
    </div>
  );
}

function Graph({ coords }){
  const plotDivRef = useRef(null);

  useEffect(() => {
    let x_max = 5;
    let x_min = 1;
    let y_max = 5;
    let y_min = 1;

    if (plotDivRef.current) {
      // Use ref instead of static ID
      Plotly.newPlot(plotDivRef.current, [
        {
          x: [coords[0]],
          y: [coords[1]],
          mode: 'markers+text',
          type: 'scatter',
          text: ['Your political place'],
          textposition: 'top center',
          hoverinfo: 'text',
          marker: {
            symbol: 'star', 
            size: 12,
            color: 'black',
            showlegend: false,
          }
        },
        {
          x: [2, 2, 4, 4], 
          y: [1.25, 4.75, 1.25, 4.75], 
          mode: 'text',
          type: 'scatter',
          text: ['Libertarian Socialist', 'Authoritarian Socialist', 'Libertarian Capitalist', 'Authoritarian Capitalist'],
          textposition: 'center',
          showlegend: false,
        },
        ], {
            xaxis: {
                autorange: true,
                range: [x_min, x_max],
                tickvals: [1, 2, 3, 4, 5],
                ticktext: [1, 2, 3, 4, 5],
                title: {
                  text: 'Economics',
                  font: {
                    family: 'Courier New, monospace',
                    size: 18,
                    color: '#7f7f7f',
                  }
                }
            },
            yaxis: {
                autorange: true,
                range: [y_min, y_max],
                tickvals: [1, 2, 3, 4, 5],
                ticktext: [1, 2, 3, 4, 5],
                title: {
                  text: 'Civics',
                  font: {
                    family: 'Courier New, monospace',
                    size: 18,
                    color: '#7f7f7f',
                  }
                }
            },
            shapes: [
              // Bottom-left quadrant (purple)
              {
                type: 'rect',
                xref: 'x',
                yref: 'y',
                x0: x_min,
                y0: y_min,
                x1: 3,
                y1: 3,
                fillcolor: 'green',
                opacity: 0.2,
              },
              // Top-left quadrant (red)
              {
                type: 'rect',
                xref: 'x',
                yref: 'y',
                x0: x_min,
                y0: 3,
                x1: 3,
                y1: y_max,
                fillcolor: 'red',
                opacity: 0.2,
              },
              // Bottom-right quadrant (green)
              {
                type: 'rect',
                xref: 'x',
                yref: 'y',
                x0: 3,
                y0: y_min,
                x1: x_max,
                y1: 3,
                fillcolor: 'purple',
                opacity: 0.2,
              },
              // Top-right quadrant (blue)
              {
                type: 'rect',
                xref: 'x',
                yref: 'y',
                x0: 3,
                y0: 3,
                x1: x_max,
                y1: y_max,
                fillcolor: 'blue',
                opacity: 0.2,
              },
            ],
            margin: { l: 30, r: 20, b: 30, t: 20 },
            showlegend: false,
        }, {staticPlot: true});
    }
  }, [coords]);
  


  return (
    <div className='GraphContainer'>
      <div ref={plotDivRef} className='politicalPlaceGraph'></div>
    </div>
    
  );

}


function QuestionCard({ prompt, number, total, questionId, parentTrackingObject }) {
  const MAX_WORDS = 100;

  // use a hook for word count
  let [wordCount, setWordCount] = useState(0);
  let [text, setText] = useState('');

  const handleTextChange = (event) => {
    const text = event.target.value;
    const words = text.split(' ').filter((word) => word !== '');

    if (words.length > MAX_WORDS || text.length > MAX_WORDS * 10) {
      return;
    }

    setWordCount(words.length);
    setText(text);

    parentTrackingObject.text = text;
  }


  return (
    <div className="QuestionCard">
      <div className="Prompt">{prompt}</div>
      <textarea className="AnswerInput" placeholder='provide your answer here' onChange={handleTextChange} value={text} />
      <QuestionCardFooter number={number} total={total} />
      <WordCounter wordCount={wordCount} MAX_WORDS={MAX_WORDS} />

    </div>
  );
}

function Header() {
  const goToWebsite = () => {
    const baseURL = window.location.protocol + "//" + window.location.host;
    window.location.href = baseURL;
  }

  return (
    <div className="Header" onClick={goToWebsite}>
      <div className="HeaderTitleBox">
        <img src={logo} className="HeaderLogo" alt="logo" />
        <div className='horizontalSpacer' />
        <div className="HeaderTitle">Your Political Place</div>
      </div>
      
      <div className="HeaderSubtitle">Write short essays. AI will guess your political place.</div>
    </div>
  );
}

function App() {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [politicalPlace, setPoliticalPlace] = useState([0, 0]);
  const [gradedAnswers, setGradedAnswers] = useState([]);
  const [gradeRequestId, setGradeRequestId] = useState(null);
  const [politicalId, setPoliticalId] = useState('');

  function prepareAnswers(pageId) {
    // get ready to show answewrs.
    setLoading(true);

    //get the gradeRequestId from the pageId
    client.getGradeRequestId(pageId, (gradeRequestId) => {

      client.checkForGrades(gradeRequestId, (gradedAnswers) => {
        // sort gradedAnswers by questionId
        let sortedGradedAnswers = gradedAnswers.sort((a, b) => {
          return a.questionId - b.questionId;
        });

        setGradedAnswers(sortedGradedAnswers);
        setQuestions([]);
        let averageEcon = 0;
        let averageLiberty = 0;
        let nzEcon = 0;
        let nzLiberty = 0;
        

        for(let answer in gradedAnswers){
          averageEcon += gradedAnswers[answer].econGrade;
          averageLiberty += gradedAnswers[answer].libertyGrade;

          if(gradedAnswers[answer].econGrade > 0){
            nzEcon++;
          }

          if(gradedAnswers[answer].libertyGrade > 0){
            nzLiberty++;
          }
        }

        averageEcon /= nzEcon;
        averageLiberty /= nzLiberty;

        setPoliticalPlace([averageEcon, averageLiberty]);
        setPoliticalId(placeLabel(averageEcon, averageLiberty));
        setLoading(false);
      },
      (error) => {
        console.log("Error preparing answers", error);
      });
      
    },
    (error) => {
      console.log("Error getting gradeRequestId from pageId.", error);
    });
  }


  // get questions from client on mount
  React.useEffect(() => {
    // check if url ends with /graded/<gradeRequestId>
    const url = window.location.href;
    const splitUrl = url.split('/');
    
    if(splitUrl.length > 2){
      const penultimate = splitUrl[splitUrl.length - 2];
      if(penultimate === 'graded'){
        const pageId = splitUrl[splitUrl.length - 1];
        prepareAnswers(pageId);
        client.getGradeRequestId(pageId, (gradeRequestId) => {
          setGradeRequestId(gradeRequestId);
        },
        (error) => {
          console.log("Error getting gradeRequestId from pageId.", error);
        });
        return;
    }
  }

    client.getPQs((questions) => {
      const qs = [];
      for(let i = 0; i < questions.length; i++){
        const q = questions[i];
        qs.push(q);
      }
      setQuestions(qs);
      setLoading(false);
    }, (error) => {
      console.log(error);
    });
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    if(!questions || questions.length === 0){
      console.log("No questions to submit");
      return;
    }

    client.submit(questions, ( grd, pageId ) => {
      setQuestions([]);
      setGradeRequestId(grd);
      
      const baseURL = window.location.protocol + "//" + window.location.host;

      // go to the graded page
      window.location.href = baseURL + "/graded/" + pageId;

    }, (error) => {
      console.log("Error submitting", error);
    });

  };

  const followUp = !loading && gradedAnswers.length > 0;
  const privacyPolicy = (<ExplainableText text="NOT PRIVATE" explanation={"Anything you write on this website may be used by anyone at any time for any reason."} />);
  
  return (
    <div className="App">
      <div className="Gutter" />
      <div className="Main">
        <Header />
        <div className="spacer" />
        {!loading && ! followUp && 
          <>
          <div className='IntroTextContainer'><div className="IntroText">
          Answer <i>writing prompts</i> below with short essays.  AI will grade and estimate your political place.  Responses are {privacyPolicy}, links are Amazon affiliate.  You may answer as many or as few as you want.
            </div></div>
          <div className="miniSpacer" />
          </>
        }

        {loading && <LoadingIndicator />}
        {!followUp && questions.map((question, index) => {

          if(index == 2 ){
            return (
              <>
              <Ad image={audibleImage} key={"ad-" + index} topText="Why read when you could listen?" bottomText="Join Audible.  Free Trial." link="https://www.amazon.com/hz/audible/mlp/membership/premiumplus?tag=yourpolitical-20"/>
              <QuestionCard
                key={question.id}
                prompt={question.prompt}
                number={index + 1}
                total={questions.length}
                questionId={question.id}
                parentTrackingObject={question}
              />
              </>
            )
          }

          if(index == 5){
            return (
              <>
              <Ad image={audibleImage} topText="You don't have time to sit still." key={"ad-" + index} bottomText="You can still consume books.  Join Audible.  Free Trial." link="https://www.amazon.com/hz/audible/mlp/membership/premiumplus?tag=yourpolitical-20"/>
              <QuestionCard
                key={question.id}
                prompt={question.prompt}
                number={index + 1}
                total={questions.length}
                questionId={question.id}
                parentTrackingObject={question}
              />
              </>
            )
          }

          if(index == questions.length - 1){
            return (
              <>
              <Ad image={audibleImage} topText="You get one free audiobook a month" bottomText="People will think you're smart." link="https://www.amazon.com/hz/audible/mlp/membership/premiumplus?tag=yourpolitical-20"/>
              <QuestionCard
                key={question.id}
                prompt={question.prompt}
                number={index + 1}
                total={questions.length}
                questionId={question.id}
                parentTrackingObject={question}
              />
              </>
            )
          }

          return (
            <QuestionCard
              key={question.id}
              prompt={question.prompt}
              number={index + 1}
              total={questions.length}
              questionId={question.id}
              parentTrackingObject={question}
            />
          );
        })}
        {followUp && (
          <div className='explainer'>
            <div className="spacer" />
            <div className="explainerTitle">Your political place</div>
            <div className="spacer" />
            <div className="miniSpacer" />
            { politicalPlace[0] > 0 && politicalPlace[1] > 0 && (<Graph coords={ politicalPlace } />)}
            <div className="spacer" />
            <div className="explainerText">The plot above shows the average of your answers in a 2-dimensional political space.  Scroll down to learn how each of your responses was evaluated.</div>
            <div className="spacer" />
          </div>
        )}

        {followUp && gradedAnswers.map((answer, index) => {
          const adSet = ads.get(politicalId, []);
          const adIndex = Math.floor(index / 3);
          if(index % 3 == 0 && adSet.length > adIndex){
            const ad = adSet[adIndex];
            console.log("Adding an ad", ad, adIndex, index, index % 3, adSet);
            return (
              <>
              <GradedAnswer
                key={answer.id}
                prompt={answer.prompt}
                answer={answer.answer}
                econGrade={answer.econGrade}
                econGradeText={answer.econGradeText}
                libertyGrade={answer.libertyGrade}
                libertyGradeText={answer.libertyGradeText}
                questionId={answer.questionId}
                gradeRequestId={gradeRequestId}
              />
              <div className="spacer" />
              <Ad image={ad.image} key={"ad-" + index} topText={"BUY this book: " + ad.topText} bottomText={ad.bottomText} link={ad.link}/>
              <div className="spacer" />
              </>
              
            )
          }
          return (
            <>
              <GradedAnswer
                key={answer.id}
                prompt={answer.prompt}
                answer={answer.answer}
                econGrade={answer.econGrade}
                econGradeText={answer.econGradeText}
                libertyGrade={answer.libertyGrade}
                libertyGradeText={answer.libertyGradeText}
                questionId={answer.questionId}
                gradeRequestId={gradeRequestId}
              />
              <div className="spacer" />
            </>
            
          );
        })}
          
        
        {!followUp && (<div className="SubmitButtonContainer">
          <button className="SubmitButton" onClick={ handleSubmit } disabled={loading} >Submit</button>
        </div>)}
        
      </div>
      <div className="Gutter" />
    </div>
  );
}

export default App;

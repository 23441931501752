import { createClient } from '@supabase/supabase-js';

const SUPABASE_URL = 'https://bxsyihpmbuqbesctyzci.supabase.co';
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ4c3lpaHBtYnVxYmVzY3R5emNpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE3MDcyMjYsImV4cCI6MjAwNzI4MzIyNn0.auASXhAgJKIwVuZ92xP5vZMa294mbX6qBOT3NA59ExA';
const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);


const client = {

    getPQs: async function (success, fail) {
        const self = this;

        // const { data: answerData, error: answerError } = await supabase
        //     .from('answers')
        //     .select('questionId', { count: 'exact' })
        //     .eq('userId', self.userId);

        // if (answerError) {
        //     console.log("Error getting answers", answerError);
        //     fail(answerError);
        //     return;
        // }

        // check if userId is null
        if (!client.userId || client.userId === 'null' || client.userId === 'undefined') {
            console.log("User id is null");
            
            // try again in 500ms
            setTimeout(() => {
                self.getPQs(success, fail);
            }, 500);
            return;
        }

        const { data: questionData, error: questionError } = await supabase
            .from('questions')
            .select('*')
            .order('id', { ascending: true })
            .limit(10)
        ;

        if (questionError) {
            console.log("Error getting questions", questionError);
            fail(questionError);
            return;
        }

        console.log("Got questions", questionData);

        success(questionData);

    },

    

    checkForGrades: async (gradeRequestId, success, fail) => {
        const { data: gradeRequestData, error: gradeRequestError } = await supabase
            .from('gradeRequests')
            .select('*')
            .eq('id', gradeRequestId);

        if (gradeRequestError) {
            console.log("Error getting grade request", gradeRequestError);
            fail(gradeRequestError);
            return;
        }

        if(gradeRequestData && gradeRequestData.length > 0){
            const grd = gradeRequestData[0];
            if(grd.status === "GRADED") {
                console.log("Grade request has been graded.  Returning graded answers.");
                
                // get the graded answers
                client.getAnswersForGradeRequest(gradeRequestId, success, fail);
            }
            else {
                console.log("Grade request has not been graded yet.  Current status: " + grd.status + ".  Checking again in 1 second.");
                setTimeout(() => {
                    client.checkForGrades(gradeRequestId, success, fail);
                }, 1000);
            }
        }
    },


    submit: async (answers, success, fail) => {
        const self = this;
        // insert each answer into the database

        //answers is an array of question objects.  each answer has a questionId, prompt, and text (the answer)
        //insert each answer into the database
        let answerArray = Array.from(answers.values());
        
        // filter to remove any answers without a text value or where the text value is an empty string
        answerArray = answerArray.filter((answer) => {
            return answer.text && answer.text !== '';
        });

        // change the id to questionId
        answerArray.forEach((answer) => {
            answer.questionId = answer.id;
            delete answer.id;

            answer.userId = client.userId;
        });

        const { data: answerData, error: answerError } = await supabase
            .from('answers')
            .insert(answerArray)
            .select('*');

    
        if (answerError) {
            console.log("Error inserting answers", answerError);
            fail(answerError);
            return;
        }

        // Having added all the answers submit a GradeRequest record
        const { data: gradeRequestData, error: gradeRequestError } = await supabase
            .from('gradeRequests')
            .insert({
                userId: client.userId,
                questionCount: answerArray.length,
        }).select('*');

        if (gradeRequestError) {
            console.log("Error inserting grade request", gradeRequestError);
            fail(gradeRequestError);
            return;
        }

        if(gradeRequestData && gradeRequestData.length > 0){
            const gradeRequestId = gradeRequestData[0].id;

            // update the answers in answerData to include the gradeRequestId
            answerData.forEach((answer) => {
                answer.gradeRequestId = gradeRequestId;
            });

            const answerIds = answerData.map((answer) => { return answer.id; });
            console.log("answer ids", answerIds);
            console.log("grade request id", gradeRequestId);

            const testAnswerIds = [177, 178];

            // select all answers with ids testAnswerIds
            const { data: testAnswerData, error: testAnswerError } = await supabase
                .from('answers')
                .select('*')
                .in('id', testAnswerIds);


            // update the answers in the database to include the gradeRequestId
            const { data: updatedAnswerData, error: updatedAnswerError } = await supabase
                .from('answers')
                .update({ gradeRequestId: gradeRequestId })
                .in('id', answerIds)
                .select('*');

            if (updatedAnswerError) {
                console.log("Error updating answers", updatedAnswerError);
                fail(updatedAnswerError);
                return;
            }

            // insert a page record
            const { data: pageData, error: pageError } = await supabase
                .from('pages')
                .insert({
                    gradeRequestId: gradeRequestId,
                })
                .select('*');

            if (pageError || !pageData || pageData.length === 0) {
                console.log("Error inserting page", pageError, pageData);
                fail(pageError);
                return;
            }

            // get the guid from the page record
            const guid = pageData[0].id;

            // return the guid
            success(gradeRequestId, guid)
        }

        else {
            fail("No grade request data");
        }

    },

    getAnswersForGradeRequest: async (gradeRequestId, success, fail) => {
        const { data: gradedAnswerData, error: gradedAnswerError } = await supabase
            .from('gradedAnswers')
            .select('*')
            .eq('gradeRequestId', gradeRequestId);

        console.log("gradeRequestId", gradeRequestId);

        if (gradedAnswerError) {
            console.log("Error getting graded questions", gradedAnswerError);
            fail(gradedAnswerError);
            return;
        }

        console.log("Answers for grade request", gradedAnswerData)
        success(gradedAnswerData);
    },

    sendFeedback: async (gradeRequestId, questionId, sentimentScore, feedback, success, fail) => {
        const { data: feedbackData, error: feedbackError } = await supabase
            .from('feedback')
            .insert({
                sentimentScore: sentimentScore,
                gradeRequestId: gradeRequestId,
                questionId: questionId,
                feedback: feedback,
                userId: client.userId
            })
            .select('*');

        if (feedbackError) {
            console.log("Error inserting feedback", feedbackError);
            fail(feedbackError);
            return;
        }

        console.log("Feedback inserted", feedbackData);
        success(feedbackData);
    },

    getGradeRequestId: async (guid, success, fail) => {
        console.log("Getting gradeRequestId for guid", guid);
        // query supabase page for gradeRequestId
        const {data, error } = await supabase
            .from('pages')
            .select('gradeRequestId')
            .eq('id', guid);

        if (error) {
            console.log("Error getting gradeRequestId", error);
            fail(error);
            return;
        }

        if(data.length === 0){
            console.log("No gradeRequestId found for guid", guid);
            fail("No gradeRequestId found for guid", guid);
            return;
        }

        const gradeRequestId = data[0].gradeRequestId;

        console.log("got GradeRequestId", gradeRequestId);
        success(gradeRequestId);
    }
};

async function createNewUserWithSupabase() {
    const { error, data } = await supabase.from('users').insert({}).select();

    if (error) {
        console.log("Error creating new user", error);
        return;
    }
    

    return data[0].id;
}

// get userId from local storage
const userId = localStorage.getItem('userId', null);

// if userId is null, create a new user
if (!userId || userId === 'null' || userId === 'undefined') {
    createNewUserWithSupabase().then((userId) => {
        localStorage.setItem('userId', userId);
        client.userId = userId;
    });
};

client.supabase = supabase;
client.userId = userId;

export default client;